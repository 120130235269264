import React, { useState, useEffect } from 'react'
import { db } from './firebase'
import { collection, query, where, getDocs } from "firebase/firestore";
import { Container, Button, ListGroup } from "react-bootstrap";

export default function DownloadSurveyData({ userId }) {
  const [surveys, setSurveys] = useState([])
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [successMessage, setSuccessMessage] = useState('')

  const fetchSurveys = async () => {
    try {
      const q = query(collection(db, "surveys"), where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
      const userSurveys = querySnapshot.docs.map((doc) => doc.data());

      setSurveys(userSurveys);
    } catch (error) {
      console.error('Error fetching surveys:', error);
    }
  };

  const handleSurveySelect = (survey) => {
    setSelectedSurvey(survey)
  };

  const downloadData = async () => {
    try {
      // Fetch all survey responses
      const q = query(collection(db, "survey_responses"), where("survey_id", "==", selectedSurvey.id))
      const surveyResponsesSnapshot = await getDocs(q)
      if (surveyResponsesSnapshot.empty) {
        console.error('No survey responses found.');
        return;
      }
  
      // Process the fetched responses to create CSV rows
      let csvRows = [];

      // Get the survey questions from the first document (to be headers)
      const firstResponseData = surveyResponsesSnapshot.docs[0].data();
      const headers = firstResponseData.response.map((answer) => answer.text);
      headers.push('Response ID')

      // TODO: Assumes that each document has the same question
      surveyResponsesSnapshot.forEach((doc) => {
        const surveyResponse = doc.data().response;
        if (surveyResponse && Array.isArray(surveyResponse)) {
          const userAnswers = {};
          userAnswers['Response ID'] = doc.data().id

          surveyResponse.forEach((answer) => {
            const question = answer.text;
            const answerValue = answer.answer;

            // Check if the answer is an object representing a location
            if (typeof answerValue === 'object' && answerValue !== null) {
              // Extract location attributes
              let { address, longitude, latitude, name } = answerValue;


              if (address) {
                address = address.includes(',') ? `"${address}"` : address;
              } else if (name) {
                name = name.includes(',') ? `"${name}"` : name;
              }

              userAnswers[`${question}_Address`] = address || '';
              userAnswers[`${question}_Longitude`] = longitude || '';
              userAnswers[`${question}_Latitude`] = latitude || '';
              userAnswers[`${question}_Name`] = name || '';

              // Add location attributes to headers if not there
              if (!(headers.includes(`${question}_Longitude`))) {
                headers.push(`${question}_Address`)
                headers.push(`${question}_Longitude`)
                headers.push(`${question}_Latitude`)
                headers.push(`${question}_Name`)
              }
            } else {
              // Regular answer
              const escapedAnswer = answerValue.includes(',') ? `"${answerValue}"` : answerValue;
              userAnswers[question] = escapedAnswer || '';

              // Add question to headers if not there
              if (!(headers.includes(question))) {
                headers.push(question)
              }
            }
          });
          // Prepare the CSV row
          csvRows.push(userAnswers);
        }
      });

      // Fill each row with the correct headers
      csvRows.forEach(row => {
        addKeysToObject(row, headers)
      })

      // Escape values containing commas with double quotes; reorder csv rows
      let escapedQuestions = Object.keys(csvRows[0]).map((question) => (question.includes(',') ? `"${question}"` : question));
      csvRows = reorderObjectKeys(csvRows, escapedQuestions)
      escapedQuestions = Object.keys(csvRows[0]).map((question) => (question.includes(',') ? `"${question}"` : question));

      // Create CSV content
      const csvContent = [
        [escapedQuestions], // CSV header row with questions as headers
        ...csvRows.map((row, index) => [...Object.values(row)]),
      ]
        .map((row) => row.join(',')) // Convert rows to CSV format
        .join('\n'); // Join rows with line breaks

      // Trigger download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
      downloadLink.setAttribute('download', 'survey_responses.csv');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      setSuccessMessage("Successful!")
    } catch (err) {
      console.error('Error downloading survey data:', err);
      setSuccessMessage("Error downloading survey data: ", err)
    }
  }

  // Helper method: Given a list of keys and an object, add the keys to the list if not already present
  function addKeysToObject(obj, keysToAdd) {
    // Ensure obj is an object
    if (typeof obj !== 'object' || obj === null) {
      throw new Error('Invalid object');
    }
  
    // Ensure keysToAdd is an array
    if (!Array.isArray(keysToAdd)) {
      throw new Error('Invalid keysToAdd, it should be an array');
    }
  
    // Loop through the keys to add
    keysToAdd.forEach((key) => {
      if (!(key in obj)) {
        // Add the key with an empty string as the value
        obj[key] = '';
      }
    });
  
    return obj;
  }

  // Helper method: given an array of objects and a specific key order, reorder object keys
  function reorderObjectKeys(arrayOfObjects, keyOrder) {
    return arrayOfObjects.map((obj) => {
      const reorderedObj = {};
  
      // Iterate through the specified key order
      keyOrder.forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          reorderedObj[key] = obj[key];
        }
      });
  
      // Add any remaining keys not in the specified order
      for (const key in obj) {
        if (!reorderedObj.hasOwnProperty(key)) {
          reorderedObj[key] = obj[key];
        }
      }
  
      return reorderedObj;
    });
  }

  useEffect(() => {
    // Fetch surveys associated with the user from Firebase
    fetchSurveys();
  }, []); // Fetch surveys only once, on component mount

  return (
    <Container>
      <h2 className="my-4">Download Survey Data</h2>
      <ListGroup>
        {surveys.map((survey, index) => (
          <ListGroup.Item
            key={index}
            action
            active={survey === selectedSurvey}
            onClick={() => handleSurveySelect(survey)}
          >
            {survey.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
      {selectedSurvey && (
        <Button className="mt-2 btn btn-warning" onClick={downloadData}>Download Data from {selectedSurvey.name}</Button>
      )}
      {successMessage !== "" && (
        <p>{successMessage}</p>
      )}
    </Container>
  )
}
