import React, { useState } from 'react'
import SignIn from "./SignIn";
import Dashboard from './Dashboard';

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [userId, setUserId] = useState('')

  return (
    <div className="App">
      {loggedIn ? (
        <Dashboard userId={userId} />
      ) : <SignIn setLoggedIn={setLoggedIn} setUserId={setUserId} /> }
    </div>
  );
}

export default App;
