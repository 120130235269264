import React, { useState } from 'react';
import { db, auth } from './firebase'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, collection, setDoc } from "firebase/firestore"; 

const SignIn = ({setLoggedIn, setUserId}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [newEmail, setNewEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
        const userRef = await signInWithEmailAndPassword(auth, email, password)
        setUserId(userRef.user.uid)
        setLoggedIn(true)
    } catch (error) {
        console.error(error)
    }
  };

  const handleSignUp = async(event) => {
    event.preventDefault()
    try {
        const newUser = await createUserWithEmailAndPassword(auth, newEmail, newPassword)
        const uid = newUser.user.uid

        await setDoc(doc(db, "users", uid), {
            email: newEmail,
            userId: uid,
        });

        setUserId(uid)
        setLoggedIn(true)
    } catch (error) {
        console.error(error)
    }

  }

  return (
    <div className="mx-2">
        <h1>OpenCity Survey Chatbot Tool</h1>
        <h4>Sign In</h4>
        <form onSubmit={handleSignIn}>
            <div>
                <label htmlFor="email">Email:</label>
                <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                />
            </div>
            <div>
                <label htmlFor="password">Password:</label>
                <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                />
            </div>
            <button type="submit">Sign In</button>
        </form>

        <h4 className="my-2 ">Sign Up</h4>
        <form onSubmit={handleSignUp}>
            <div>
                <label htmlFor="email">Email:</label>
                <input
                type="email"
                id="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
                />
            </div>
            <div>
                <label htmlFor="password">Password:</label>
                <input
                type="password"
                id="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                />
            </div>
            <button type="submit">Sign Up</button>
        </form>

    </div>
  );
};

export default SignIn;
