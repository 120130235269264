import React, { useState } from "react";
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from './firebase'
import { Container, Form, Button, ListGroup } from "react-bootstrap";

function CreateSurvey({userId}) {
  const [successMessage, setSuccessMessage] = useState("")
  const [surveyName, setSurveyName] = useState("");
  const [active, setActive] = useState(false)
  const [survey, setSurvey] = useState([
    {
      text: "",
      options: {
        list: [],
        type: "button"
      },
      condition: null,
    },
  ]);

  const addQuestion = () => {
    const newSurvey = [
      ...survey,
      {
        text: "",
        options: {
          list: [],
          type: "button"
        },
        condition: null,
      },
    ];
    setSurvey(newSurvey);
  };

  const deleteQuestion = (index) => {
    const updatedSurvey = [...survey];
    updatedSurvey.splice(index, 1);
    setSurvey(updatedSurvey);
  };

  const deleteOption = (questionIndex, optionIndex) => {
    const updatedSurvey = [...survey];
    updatedSurvey[questionIndex].options.list.splice(optionIndex, 1);
    setSurvey(updatedSurvey);
  };

  const clearCondition = (index) => {
    const updatedSurvey = [...survey];
    updatedSurvey[index].condition = null;
    setSurvey(updatedSurvey);
  };

  const updateQuestion = (index, question) => {
    const updatedSurvey = [...survey];
    updatedSurvey[index].text = question;
    setSurvey(updatedSurvey);
  };

  const updateCondition = (index, condition) => {
    const updatedSurvey = [...survey];
    updatedSurvey[index].condition = condition;
    setSurvey(updatedSurvey);
  };

  const updateOptionsType = (index, type) => {
    const updatedSurvey = [...survey];
    updatedSurvey[index].options.type = type;
    setSurvey(updatedSurvey);
  };

  const addOption = (index) => {
    const updatedSurvey = [...survey];
    updatedSurvey[index].options.list.push("");
    setSurvey(updatedSurvey);
  };

  const updateOption = (questionIndex, optionIndex, option) => {
    const updatedSurvey = [...survey];
    updatedSurvey[questionIndex].options.list[optionIndex] = option;
    setSurvey(updatedSurvey);
  };

  // Function to handle toggling the "active" state
  const handleActiveToggle = () => {
    setActive(!active);
  };

  const uploadSurveyToFirebase = async (surveyData) => {
    try {
      // Generate a unique ID for the survey
      const surveyRef = doc(collection(db, 'surveys'));
  
      // Add the generated ID to the survey data
      const surveyWithId = {
        id: surveyRef.id,
        userId,
        name: surveyName,
        active,
        questions: survey,
      };
  
      // Add the survey with the generated ID to the "surveys" collection
      await setDoc(surveyRef, surveyWithId);

      // Upon successful upload, reset everything
      setSurvey([
        {
          text: "",
          options: {
            list: [],
            type: "button"
          },
          condition: null,
        },
      ])
      setSurveyName("")
      setActive(false)

      // Show "success message"
      setSuccessMessage("Successful!")

    } catch (error) {
      console.error("Error uploading survey to Firebase: ", error);
      setSuccessMessage("Error uploading survey: ", error)
      throw error; // Rethrow the error for handling at a higher level
    }
  };

  return (
    <Container>
      <h2 className="my-4">Create Survey</h2>
      <Form.Group controlId="surveyName">
        <Form.Label>Survey Name</Form.Label>
        <Form.Control
          type="text"
          value={surveyName}
          onChange={(e) => setSurveyName(e.target.value)}
          placeholder="Enter survey name"
        />
      </Form.Group>
      <Form.Group controlId="active">
        <Form.Check
          type="switch"
          id="active-switch"
          label={active ? "Active (visible to users)" : "Inactive (private)"}
          checked={active}
          onChange={handleActiveToggle}
        />
      </Form.Group>
      <ListGroup className="my-4">
        {survey.map((q, questionIndex) => (
          <ListGroup.Item key={questionIndex} className="my-2 border border-secondary">
            <Form.Group controlId={`question-${questionIndex}`} className="my-2">
              <Form.Label><b>Question {questionIndex + 1}</b></Form.Label>
              <Form.Control
                type="text"
                value={q.text}
                onChange={(e) => updateQuestion(questionIndex, e.target.value)}
                placeholder="Enter your question"
              />
            </Form.Group>
            <Button variant="secondary" onClick={() => addOption(questionIndex)}>
              Add Option
            </Button>
            <Button
              variant="danger"
              className="ml-2"
              onClick={() => deleteQuestion(questionIndex)}
            >
              Delete Question
            </Button>
            {
              q.options.list.length > 0 && (
                <div className="mt-3">
                  <Form.Label><i>Options:</i></Form.Label>
                  {q.options.list.map((option, optionIndex) => (
                    <div key={optionIndex} className="mt-2">
                      <Form.Group controlId={`option-${questionIndex}-${optionIndex}`}>
                        <Form.Control
                          type="text"
                          value={option}
                          onChange={(e) =>
                            updateOption(questionIndex, optionIndex, e.target.value)
                          }
                          placeholder={`Option ${optionIndex + 1}`}
                        />
                      </Form.Group>
                      <Button
                        variant="danger"
                        onClick={() => deleteOption(questionIndex, optionIndex)}
                        className="mt-1"
                      >
                        Remove Option
                      </Button>
                    </div>
                  ))}
                <Form.Group controlId={`options-type-${questionIndex}`}>
                  <Form.Label><i>Options Type:</i></Form.Label>
                  <Form.Control
                    as="select"
                    value={q.options.type || "button"} // Default to "button" if not specified
                    onChange={(e) =>
                      updateOptionsType(questionIndex, e.target.value)
                    }
                  >
                    <option value="button">Button</option>
                    <option value="list">List</option>
                  </Form.Control>
                </Form.Group>
                </div>
              )
            }

            {questionIndex > 0 && (
              <div className="mt-3">
                <Form.Label><i>Condition:</i></Form.Label>
                <Form.Control
                  as="select"
                  value={q.condition ? q.condition.index : "none"}
                  onChange={(e) =>
                    updateCondition(questionIndex, {
                      ...q.condition,
                      index: Number(e.target.value),
                    })
                  }
                >
                  <option value="none">None</option>
                  {survey
                    .filter((_, i) => i < questionIndex)
                    .map((_, i) => (
                      <option key={i} value={i}>
                        Question {i + 1}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control
                  as="select"
                  value={q.condition ? q.condition.comparison : "none"}
                  onChange={(e) =>
                    updateCondition(questionIndex, {
                      ...q.condition,
                      comparison: e.target.value,
                    })
                  }
                >
                  <option value="none">None</option>
                  <option value="equals">Equals</option>
                  <option value="does not equal">Not Equals</option>
                  <option value="greater than">Greater Than</option>
                  <option value="less than">Less Than</option>
                </Form.Control>
                <Form.Control
                  type="text"
                  value={q.condition ? q.condition.value : ""}
                  onChange={(e) =>
                    updateCondition(questionIndex, {
                      ...q.condition,
                      value: e.target.value,
                    })
                  }
                  placeholder="Value to check"
                />
                <Button
                  variant="danger"
                  onClick={() => clearCondition(questionIndex)}
                  className="mt-2"
                >
                  Clear Condition
                </Button>
              </div>
            )}
          </ListGroup.Item>
        ))}
        <Button variant="primary" onClick={addQuestion}>
          Add Question
        </Button>
        <Button variant="success" onClick={uploadSurveyToFirebase} className="mt-1">
          Upload To Database
        </Button>
        {successMessage !== "" && (<p>Success!</p>)}
      </ListGroup>
      <pre>
        {JSON.stringify(
          { 
            name: surveyName, 
            active,
            questions: survey 
          }, null, 2
        )}
      </pre>
    </Container>
  );
}

export default CreateSurvey;
