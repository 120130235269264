import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CreateSurveyPage from './CreateSurvey';
import UpdateSurveyPage from './UpdateSurvey';
import UploadJSONPage from './UploadJSON';
import DownloadSurveyDataPage from './DownloadSurveyData';

function Dashboard({userId}) {
  const [currentPage, setCurrentPage] = useState(null);

  const renderPage = () => {
    switch (currentPage) {
      case 'create':
        return <CreateSurveyPage userId={userId} />;
      case 'update':
        return <UpdateSurveyPage userId={userId} />;
      case 'upload':
        return <UploadJSONPage userId={userId}/>;
      case 'download':
        return <DownloadSurveyDataPage userId={userId} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <h2 className="my-4">Dashboard</h2>
      <Row>
        <Col md={3}>
          <Button
            variant="primary"
            className="mb-3"
            block
            onClick={() => setCurrentPage('create')}
          >
            Create Survey
          </Button>
        </Col>
        <Col md={3}>
          <Button
            variant="success"
            className="mb-3"
            block
            onClick={() => setCurrentPage('update')}
          >
            Update Survey
          </Button>
        </Col>
        <Col md={3}>
          <Button
            variant="info"
            className="mb-3"
            block
            onClick={() => setCurrentPage('upload')}
          >
            Upload JSON
          </Button>
        </Col>
        <Col md={3}>
          <Button
            variant="secondary"
            className="mb-3"
            block
            onClick={() => setCurrentPage('download')}
          >
            Download Survey Data
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>{renderPage()}</Col>
      </Row>
    </Container>
  );
}

export default Dashboard;