import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from './firebase';

function UploadJSON({userId}) {
  const [jsonContent, setJsonContent] = useState(null);
  const [successMessage, setSuccessMessage] = useState('')

  // Function to handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const content = JSON.parse(event.target.result);
          setSuccessMessage('')
          setJsonContent(content);
        } catch (error) {
          console.error('Error parsing JSON file:', error);
          setJsonContent(null);
        }
      };
      reader.readAsText(file);
    }
  };

  const uploadJsonToFirebase = async (surveyData) => {
    try {
      if (jsonContent) {
        const surveyRef = doc(collection(db, 'surveys'));
        const updatedSurvey = {
          ...jsonContent,
          id: surveyRef.id,
          userId,
        }

        await setDoc(surveyRef, updatedSurvey); 

        // Show "success message"
        setJsonContent(null)
        setSuccessMessage("Successful!")
      } else {
        console.log("Error, couldn't do it!")
      }
    } catch (error) {
      console.error("Error uploading survey to Firebase: ", error);
      setSuccessMessage("Error uploading survey: ", error)
      throw error; // Rethrow the error for handling at a higher level
    }
  };

  return (
    <Container>
      <h2 className="my-4">Upload JSON</h2>
      <Form>
        <Form.Group controlId="jsonFile">
          <Form.Label>Upload JSON File</Form.Label>
          <p>(Make sure your survey document has the correct attributes and format)</p>
          <Form.Control type="file" accept=".json" onChange={handleFileChange} />
        </Form.Group>
      </Form>
      {jsonContent && (
        <div>
          <h3>JSON Content:</h3>
          <pre>{JSON.stringify(jsonContent, null, 2)}</pre>
          <Button variant="warning" onClick={uploadJsonToFirebase}>
            Upload to Firebase
          </Button>
        </div>
      )}
      {successMessage !== '' && (
        <p>{successMessage}</p>
      )}
    </Container>
  );
}

export default UploadJSON;
